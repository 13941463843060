<template>
  <div class="add-practitioner">
    <Stepper :isPrevStepRequired="true" :step="step" :texts="steps" />
    <v-row v-if="step === 1" class="pa-2">
      <v-col class="header" cols="12">
        <h4 class="title-text">
          Register <span v-if="kind === 'child'">child </span><span v-if="kind === 'adult'">adult </span> dependent
        </h4>
        <p class="subtitle-text">
          For Dependents Under 18 Years Old: As the primary account holder, you have the ability to create profiles for
          your dependents who are minors. You can do this directly through your account by clicking
          <a class="font-weight-bold" @click="addDependent()"> here</a>.
        </p>
        <p class="subtitle-text">
          For Dependents Aged 18 and Above:You can invite them to Axis.Clinic under your family subscription by
          completing invite form
          <a class="font-weight-bold" @click="addDependent('adult')"> here</a>. Once submitted, we will send an
          invitation to your dependent, enabling them to set up their own profile. This process adheres to privacy
          guidelines, ensuring the confidentiality and autonomy of their healthcare information.
        </p>
        <p class="subtitle-text">Personal Information is required to help doctor make better diagnostic</p>
        <p class="subtitle-text">
          We take confidential data seriously. Only a doctor will receive this information for a more personalized
          treatment.
        </p>
      </v-col>
    </v-row>
    <v-row v-if="step === 1" class="form pa-2">
      <v-col class="ps_input-group mb-2" cols="12">
        <h6 class="title-text">Complete Required Information</h6>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.firstName") }}
          <span class="star-required">*</span>
        </span>
        <BaseInput
          v-model="firstName"
          :errors="firstNameErrors"
          fieldName="firstName"
          placeholder="First Name"
          @blur="$v.firstName.$touch()"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.lastName") }}
          <span class="star-required">*</span>
        </span>
        <BaseInput
          v-model="lastName"
          :errors="lastNameErrors"
          fieldName="lastName"
          placeholder="Last Name"
          @blur="$v.lastName.$touch()"
        />
      </v-col>
      <v-col cols="12">
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.dateOfBirth") }}
          <span class="star-required">*</span>
        </span>
        <DatePicker
          :dense="true"
          :errors="birthDateErrors"
          :height="47"
          :hide-details="true"
          :maxDate="new Date().toISOString().substr(0, 10)"
          :placeholder="$t('general.inputs.dateOfBirth')"
          :value="birthDate"
          @blur="$v.birthDate.$touch()"
          @setDate="onChangeDate"
        />
        <span class="heading-5 text-left d-block pb-2 pt-3">
          Gender
          <span class="star-required">*</span>
        </span>
        <Switcher v-model="gender" :items="$t('general.genders')" class-name="mb-4 mt-4" @change="onGenderChange" />
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.address") }}
        </span>
        <BaseInput
          v-model="address"
          :placeholder="$t('general.inputs.address')"
          autocomplete="off"
          class="mb-4"
          fieldName="address"
          @change="onInputChange"
        />
        <span class="heading-5 text-left d-block pb-2">
          Zip Code
          <span class="star-required">*</span>
        </span>
        <BaseInput
          v-model="postalCode"
          :errors="postalCodeErrors"
          autocomplete="off"
          class="marginTop12 mb-4"
          fieldName="postalCode"
          placeholder="Zip Code"
          @change="onInputChange"
        />
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.city") }}
          <span class="star-required">*</span>
        </span>
        <v-autocomplete
          :errors="cityErrors"
          :items="predictionsValue"
          :label="$t('general.inputs.city')"
          :loading="isLoadingPredictions"
          :search-input.sync="predictionsSearch"
          :value="city"
          class="bd-grey mb-4 autocomplete"
          clearable
          fieldName="city"
          hide-details
          hide-no-data
          placeholder="Enter city"
          solo
          @change="onSelectPredictions"
        ></v-autocomplete>
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.state") }}
          <span class="star-required">*</span>
        </span>
        <StateSelect v-model="state" class="marginTop12 mb-4" @blur="$v.state.$touch()" @change="stateSelectChange" />
        <span class="heading-5 text-left d-block pb-2">
          Last 4 digits of Social Security Number
          <span class="star-required">*</span>
        </span>
        <BaseInput
          v-model="socialSecurityNumber"
          :errors="socialSecurityNumberErrors"
          class="mb-4 marginTop12"
          fieldName="socialSecurityNumber"
          maskPattern="xxxx-xx-####"
          placeholder="Last 4 digits of Social Security Number"
          @blur="$v.socialSecurityNumber.$touch()"
        />
        <span class="heading-5 text-left d-block pb-2"> Home phone number </span>
        <PhoneInput
          v-model="homePhoneNumber"
          class="mb-4"
          fieldName="homePhoneNumber"
          placeholder="Home phone number"
          @change="onInputChange"
        />
        <span class="heading-5 text-left d-block pb-2">
          Mobile phone number
          <span class="star-required">*</span>
        </span>
        <PhoneInput
          v-model="phoneNumber"
          :errors="phoneNumberErrors"
          class-name="mb-4"
          fieldName="phoneNumber"
          placeholder="Mobile phone number"
          @blur="$v.phoneNumber.$touch()"
          @change="onInputChange"
        />
        <span class="heading-5 text-left d-block pb-2"> Email </span>
        <BaseInput v-model="email" class="mb-4" fieldName="email" placeholder="Email (optional)" />
      </v-col>
      <v-col cols="12">
        <PrimaryButton
          :disabled="this.$v.$invalid"
          :full-width="true"
          :loading="buttonLoading"
          text="Finish Patient Account Creating"
          @onClick="saveChanges"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === 2" class="step-2 pa-2">
      <v-col class="header" cols="12">
        <h4 class="title-text green--text">Congratulations!</h4>
        <h4 class="title-text">Patient have been registered in the system</h4>
        <p class="subtitle-text">
          To add another Patient
          <span class="link pointer" @click="onNew">click here</span>
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { DependentsApi } from "@/api/dependents";
import DatePicker from "@/components/shared/DatePicker";
import PhoneInput from "@/components/shared/PhoneInput";
import StateSelect from "@/components/shared/StateSelect";
import Stepper from "@/components/shared/Stepper";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Switcher from "@/components/uikit/Switcher";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { validatorErrors } from "@/mixins/validatorErrors";
import { useAuthStore } from "@/pinia-store/auth";
import { useDependentsStore } from "@/pinia-store/dependents";
import { usePatientStore } from "@/pinia-store/patient";
import { GenderEnum } from "@/types/PractitionerResponseDto";
import { routesEnum } from "@/types/Routes.enum";
import { defaultLocale } from "@/utils/constants";
import { calcAge, errToText } from "@/utils/conversion";

const googleAutocompleteService = new window.google.maps.places.AutocompleteService();

export default {
  name: "RegisterDependent",
  components: {
    PhoneInput,
    Switcher,
    DatePicker,
    Stepper,
    StateSelect,
    PrimaryButton,
    BaseInput,
  },
  mixins: [validatorErrors],

  data: () => {
    return {
      step: 1,
      kind: "child",
      gender: GenderEnum.Other,
      socialSecurityNumber: "",
      buttonLoading: false,
      isLoadingPredictions: false,
      email: "",
      oldId: "",
      firstName: "",
      qualification: "",
      address: "",
      state: "",
      predictionsSearch: "",
      city: "",
      postalCode: "",
      predictions: [],
      lastName: "",
      locale: defaultLocale,
      loading: false,
      birthDate: "",
      homePhoneNumber: "",
      phoneNumber: "",
    };
  },
  watch: {
    predictionsSearch(val) {
      if (!val || val === this.name) return;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.isLoadingDrugs = true;
        const displaySuggestions = (predictions, status) => {
          if (status != window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
            return;
          }
          this.predictionsResults = predictions;
          this.predictions = predictions.map((pr) => pr?.terms?.[0].value || "");
        };
        googleAutocompleteService.getPlacePredictions({ input: val, types: ["(cities)"] }, displaySuggestions);
      }, 300);
    },
  },

  computed: {
    ...mapState(useAuthStore, ["uid"]),
    predictionsValue() {
      return this.city ? [...this.predictions, this.city] : this.predictions;
    },

    steps() {
      return {
        _1: "Complete Required Information",
        _2: "Create Patient account",
      };
    },
  },
  methods: {
    ...mapActions(usePatientStore, ["getPatientProxyAccounts"]),
    ...mapActions(useDependentsStore, ["setOldDependents"]),
    addDependent(kind = "child") {
      this.$router.push({ name: routesEnum.patientDependentsRegister, query: { kind } });
      this.kind = kind;
    },
    onInputChange(value, fieldName) {
      if (this.$v?.[fieldName]) this.$v[fieldName].$touch();
      this[fieldName] = value;
      if (this.$v?.[fieldName]) this.$v[fieldName].$touch();
    },
    onSelectPredictions(selectedValue) {
      const item = this.predictionsResults.find((item) => item?.terms?.[0].value === selectedValue);
      if (item) {
        this.city = item?.terms?.[0].value || "";
      }
    },
    goToPage(number) {
      this.step = number;
    },
    onChangeDate(value) {
      if (calcAge(value) < 18 && this.kind !== "child") {
        snackBarEventBus.$emit(snackBarEventName, {
          message: `Adults dependents must be over 18 years old`,
          type: "info",
        });
        return;
      }
      if (calcAge(value) > 18 && this.kind === "child") {
        snackBarEventBus.$emit(snackBarEventName, {
          message: `Children dependents must be under 18 years old`,
          type: "info",
        });
        return;
      }
      this.birthDate = value;
    },
    onGenderChange(gender) {
      this.gender = gender;
    },
    onNew() {
      this.$v.$reset();
      this.step = 1;
      this.buttonLoading = false;
      this.city = "";
      this.address = "";
      this.birthDate = "";
      this.socialSecurityNumber = "";
      this.homePhoneNumber = "";
      this.postalCode = "";
      this.email = "";
      this.firstName = "";
      this.oldId = "";
      this.state = "";
      this.lastName = "";
      this.locale = defaultLocale;
      this.loading = false;
    },
    stateSelectChange(state) {
      this.state = state;
    },
    saveChanges: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.buttonLoading = true;
        const body = {
          email: this.email,
          gender: this.gender,
          address: this.address,
          city: this.city,
          postalCode: this.postalCode,
          firstName: this.firstName,
          lastName: this.lastName,
          locale: this.locale,
          birthDate: moment(this.birthDate).utc(true).format("YYYY-MM-DD"),
          socialSecurityNumber: this.socialSecurityNumber.split("xxx-xx-")[1],
          homePhoneNumber: this.homePhoneNumber,
          phoneNumber: this.phoneNumber,
          state: this.state,
          ...(this.oldId ? { oldId: this.oldId } : {}),
        };
        await DependentsApi.createV1(body);
        const dependents = await DependentsApi.getByInviterId(this.uid);
        this.setOldDependents(dependents);
        this.getPatientProxyAccounts();
        this.step = 2;
      } catch (err) {
        console.error(JSON.stringify(err));
        if (errToText(err))
          snackBarEventBus.$emit(snackBarEventName, {
            message: errToText(err),
            type: "error",
          });
        else
          snackBarEventBus.$emit(snackBarEventName, {
            message: "Validation failed!",
            type: "error",
          });
      } finally {
        this.buttonLoading = false;
      }
    },
  },
  mounted() {
    this.kind = this.$route.query.kind || "child";

    this.validateFields = [
      "firstName",
      "lastName",
      "birthDate",
      "postalCode",
      "city",
      "state",
      "socialSecurityNumber",
      "phoneNumber",
    ];
    //  goes with autocomplete details
    if (this.$route.query && this.$route.query.id) {
      this.firstName = this.$route.query?.firstName || "";
      this.lastName = this.$route.query?.lastName || "";
      this.birthDate = this.$route.query?.birthDate || "";
      this.oldId = this.$route.query?.id || "";
      this.phoneNumber = this.$route.query?.phoneNumber || "";
      this.gender = this.$route.query?.gender || "other";
      if (this.$route.query.socialSecurityNumber && this.$route.query.socialSecurityNumber.length === 4)
        this.socialSecurityNumber = `xxx-xx-${this.$route.query.socialSecurityNumber}`;
    }
  },
};
</script>

<style lang="scss" scoped>
.add-practitioner {
  background-color: white;

  .header {
    margin: 0 auto;

    margin-top: 72px;
    justify-content: center;
    max-width: 680px;
    @include mobile {
      margin-top: 48px;
    }

    .title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #33343e;
      @include mobile {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .subtitle-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.32px;

      &:first-of-type {
        padding-top: 15px;
      }
    }
  }

  .form {
    max-width: 464px;
    background: #ffffff;
    border: 1px solid #e7e8f2;
    border-radius: 6px;
    margin: 36px auto 0;

    .title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #33343e;
    }
  }

  .step-2 {
    .header {
      .title-text {
        font-size: 28px;
        line-height: 34px;
      }

      .subtitle-text {
        margin: 0 auto;
        max-width: 500px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.32px;
      }
    }
  }
}
</style>
